import React, { useRef } from 'react';
import { useLocation } from '@reach/router';
import { graphql } from 'gatsby';
import { nanoid } from 'nanoid';
import Container from '../components/Container';
import DefaultLayout from '../layouts/Default';
import TimelineEntry from '../components/CMSBlocks/TimelineEntry';
import { Chevron } from '../components/Header/Icons';
import WhatsNextCloud from '../images/whats-next-cloud.png';
import SEO from '../components/SEO';
import Hero from '../components/CMSBlocks/Hero';

const OurHistory = ({ data }) => {
	const TimelineEntries = data.allDatoCmsOurHistory.nodes;

	const dropDown = useRef();
	const buttonRef = useRef('1980s-1990');

	const location = useLocation();

	const FilterButton = ({ href, children }) => (
		<div className="z-20 w-full lg:z-0">
			<a
				href={href}
				type="button"
				onClick={() => {
					buttonRef.current.innerHTML = children;
					dropDown.current.classList.toggle('hidden');
				}}
				className="inline-block w-full p-3 font-bold text-center border-b cursor-pointer lg:mx-0 whitespace-nowrap border-secondary-400 lg:my-1 hover:bg-brand-100 hover:text-white focus:text-white focus:bg-brand-100 lg:border lg:w-fit text-brand-400"
			>
				{children}
			</a>
		</div>
	);

	return (
		<>
			<SEO path={location.pathname} title="Our history" />
			<Hero
				bgImg="https://www.datocms-assets.com/75137/1659016272-bakery-stock-image-hero.png"
				title="Our History"
				description="From humble beginnings in Salford to an enterprise spanning three continents, see how the Peninsula Group grew into the global success it is today."
			/>
			<section>
				<Container>
					<div className="py-4 lg:pb-16 lg:pt-12">
						<h2 className="w-full text-xl text-center lg:w-3/4 md:text-2xl">
							Explore Our History
						</h2>
					</div>
					<div className="relative flex flex-col-reverse lg:flex-row">
						<div className="w-full md-vertical-line-div lg:w-3/4">
							{TimelineEntries.map(
								(
									{
										timelineHeader,
										timelineYear,
										timelineContentNode,
										timelineImage,
									},
									i
								) => (
									<div className="flex flex-col my-2" key={nanoid()}>
										<div className="w-full">
											<TimelineEntry
												i={i}
												alt={timelineImage?.alt}
												timelineHeader={timelineHeader || ''}
												timelineYear={timelineYear}
												timelineContentNode={timelineContentNode}
												imgSrc={timelineImage?.url}
											/>
										</div>
									</div>
								)
							)}
							<div className="relative text-center">
								<img src={WhatsNextCloud} alt="whats next for Peninsula Global" />
								<div className="absolute top-1/2 lg:top-2/3 right-1/2 translate-y-2/4 translate-x-2/4">
									<h4 className="p-0 m-0 text-lg font-normal leading-4 md:text-xl xl:text-2xl">
										What’s next?
									</h4>
									<h4 className="text-lg font-bold leading-4 md:text-xl xl:text-2xl">
										The sky is the limit
									</h4>
								</div>
							</div>
						</div>
						<aside className="w-full lg:w-1/4 bg-white shadow-lg lg:shadow-none p-4 lg:h-fit md:px-2.5 lg:px-8 lg:my-8 md:mt-2 lg:overflow-hidden top-12 md:top-16  sticky md:mb-8 lg:mb-0">
							<h4 className="px-1 mb-3 text-sm font-normal md:text-base">
								Sort by year:
							</h4>

							<div className="z-30 flex flex-row pb-2 pointer-events-auto lg:flex-col lg:bg-transparent">
								<div className="relative w-full select-none dropdown lg:hidden ">
									<button
										type="button"
										className="w-full px-3 py-2 text-left border-2 dropdown-btn border-brand-300"
										onClick={() => dropDown.current.classList.toggle('hidden')}
									>
										<span ref={buttonRef}>1980s - 1990</span>
										<Chevron className="inline float-right h-6 mt-auto" />
									</button>

									<div
										ref={dropDown}
										className="absolute z-30 hidden w-full bg-white shadow-lg dropdown-content lg:shadow-none"
									>
										<FilterButton href="#1983">1980s - 1990</FilterButton>
										<FilterButton href="#1991">1990s - 2000</FilterButton>
										<FilterButton href="#2002">2000s - 2010</FilterButton>
										<FilterButton href="#2010">2010s - 2020</FilterButton>
										<FilterButton href="#2020">2020s - Present</FilterButton>
									</div>
								</div>

								<ul className="sticky hidden lg:block">
									<FilterButton href="#1983">1980s - 1990</FilterButton>
									<FilterButton href="#1991">1990s - 2000</FilterButton>
									<FilterButton href="#2002">2000s - 2010</FilterButton>
									<FilterButton href="#2010">2010s - 2020</FilterButton>
									<FilterButton href="#2020">2020s - Present</FilterButton>
								</ul>
							</div>
						</aside>
					</div>
				</Container>
			</section>
		</>
	);
};

export const query = graphql`
	query MyQuery {
		allDatoCmsOurHistory(sort: { fields: timelineYear, order: ASC }) {
			nodes {
				timelineYear
				timelineHeader
				timelineImage {
					alt
					url
					gatsbyImageData(
						layout: CONSTRAINED
						width: 540
						imgixParams: { auto: "compress" }
					)
				}
				timelineContentNode {
					childMdx {
						body
					}
				}
			}
		}
	}
`;

const WrappedOurHistory = (props) => (
	<DefaultLayout fullWidth>
		<OurHistory {...props} />
	</DefaultLayout>
);

export default WrappedOurHistory;

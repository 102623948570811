import React, { useEffect, useRef, useMemo, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import cn from 'classnames';
import CopyBlock from '../../CopyBlock';

const TimelineEntry = ({ timelineHeader, timelineYear, timelineContentNode, imgSrc, i }) => {
	const [active, setActive] = useState(false);
	const imgRef = useRef();

	const callbackFunction = (entries, observer) => {
		const [entry] = entries;
		setActive(entry.isIntersecting);
		if (entry.intersectionRatio > 0) {
			observer.unobserve(entry.target);
		}
	};

	const options = useMemo(
		() => ({
			root: null,
			rootMargin: '-30px',
			threshold: 0.1,
		}),
		[]
	);

	useEffect(() => {
		// eslint-disable-next-line compat/compat
		const observer = new IntersectionObserver(callbackFunction, options);
		const currentTarget = imgRef.current;
		if (currentTarget) observer.observe(currentTarget);
	}, [imgRef, options]);

	useEffect(() => {
		AOS.init({
			once: true,
		});
	}, []);

	return (
		<div
			id={timelineYear}
			className={` relative md:scroll-mt-24 -mt-48 pt-48 lg:mt-0 lg:pt-0  flex flex-col-reverse justify-between w-full md:min-h-[16rem] border-brand-200 ${
				i % 2 ? 'md:flex-row md:text-right   ' : 'md:flex-row-reverse md:text-left   '
			}`}
			data-aos="zoom-in"
		>
			<div
				className={` flex flex-col md:flex-row items-start md:justify-center md:items-center p-3 md:p-6 w-full !text-black ${
					!imgSrc ? 'text-left' : 'md:w-[45%]'
				} `}
			>
				<div className="w-full p-6 md:bg-secondary-100">
					<div className="relative">
						<span
							className={`hidden xl:block absolute mr-0 h-0 w-0 top-1/3  border-x-8 border-x-transparent border-b-[12px] border-b-secondary-100 ${
								i % 2 ? 'rotate-90 -right-[2.25rem]' : '-rotate-90 -left-[2.25rem]'
							}`}
						/>
					</div>
					<h1 className="text-2xl font-bold text-brand-100 md:hidden">{timelineYear}</h1>
					<div
						className={`absolute items-center justify-center hidden xl:z-10 lg:mt-4 md:flex w-20 h-20 bg-brand-100 rounded-full ${
							i % 2
								? 'right-1/2 top-1/2 lg:top-1/4 -mr-[2.5rem]'
								: 'left-1/2 top-1/2 lg:top-1/4 -ml-[2.5rem]'
						}`}
					>
						<p className="text-xl font-bold text-white">{timelineYear}</p>
					</div>
					{timelineHeader && (
						<h1 className="py-1 text-xl font-bold text-brand-400">{timelineHeader}</h1>
					)}
					<CopyBlock>{timelineContentNode.childMdx.body}</CopyBlock>
				</div>
			</div>

			<div
				ref={imgRef}
				className={cn(
					`h-56 lg:h-[auto] pt-1 my-2 md:mb-12 lg:mb-0 md:w-[35%] lg:order-none bg-center bg-cover bg-no-repeat block mask-oblong-rect io-animate io-animate-fast`,
					{
						hidden: !imgSrc,
						'io-show': active,
					}
				)}
				style={{ backgroundImage: `url(${imgSrc})` }}
			/>
		</div>
	);
};

export default TimelineEntry;
